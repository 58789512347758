import React from "react";
import HostingLocal from "../assets/hebergementLocal.png";
import HostingCloud from "../assets/hebergementCloud.png";


const HostingMain: React.FC = () => {
  return (
    <div className="hostingMain">
        <img src={HostingLocal} alt="" />
        <img src={HostingCloud} alt="" />
    </div>
  );
};

export default HostingMain;
