import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Register from "../components/register";

const Services: React.FC = () => {
  
 
  return (
  <div>
    <Navbar /> 
    <hr  className="hrHomeBody"/>
    <Register/>
    <Footer/>
  </div>
  );
}

export default Services;