import React, { useState } from "react";
import { Link } from "react-router-dom";
import geneseLogo from "../assets/LOGO GENESE 1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import UserAccount from "../components/userAccount";

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {   
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="navbar">
        <div className="geneseLogo">
          <Link className="geneseLogoLink" to="/home">
            <img className="logo" src={geneseLogo} alt="" />
          </Link>
        </div>
        <div className={`menu ${isMenuOpen ? "open" : ""}`}>
          <div className="menu-item">
            <Link className="accueil menuElements respMenuElem" to="/home">
              Accueil
            </Link>
          </div>
          <div className="menu-item">
            <div className="menu-item-divider"></div> 
            <div className="dropdown">
              <span className="nosProduits menuElements respMenuElem">Nos Produits</span>
              <div className="dropdown-content">
                <Link className="materiels dropDownItem" to="/matériel">
                  Matériels
                </Link>
                <Link className="logiciels dropDownItem" to="/logiciels">
                  Logiciels
                </Link>
              </div>
            </div>
          </div>
          <div className="menu-item">
            <div className="menu-item-divider"></div> 
            <div className="dropdown-services">
              <span className="services menuElements respMenuElem">Services</span>
              <div className="dropdown-content-services">
                <Link className="telephonie dropDownItem link-no-underline" to="/téléphonie">
                  Téléphonie
                </Link>
                <Link className="securite dropDownItem link-no-underline" to="/sécurité">
                  Sécurité
                </Link>
                <Link className="hebergement dropDownItem link-no-underline" to="/hébergement">
                  Hébergement
                </Link>
              </div>
            </div>
          </div>
          <div className="menu-item">
            <div className="menu-item-divider"></div> 
            <Link className="contact menuElements respMenuElem" to="/contact">
              Contact
            </Link>
          </div>
          <div className="menu-item">
            <div className="menu-item-divider"></div> 
            <Link className="apropos menuElements aboutDisappear respMenuElem" to="/à-propos">
              À propos
            </Link>
          </div>
        </div>
        <div className="userAccountNavDiv">
        <UserAccount /> 
        </div>   
      </div>
      <div className={`burger-icon ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} className="burger-menu"/>
      </div>
      {isMenuOpen && (   
        <div className="overlay-menu" onClick={closeMenu}>
          <div className="menu">
            <div className="menu-item">
              <Link className="accueil menuElements respMenuElem" to="/home">
                Accueil
              </Link>
            </div>
            <div className="menu-item">
              <div className="menu-item-divider"></div>
              <div className="dropdown">
                <span className="nosProduits menuElements respMenuElem">Nos Produits</span>
                <div className="dropdown-content">
                  <Link className="materiels dropDownItem" to="/matériel">
                    Matériels
                  </Link>
                  <Link className="logiciels dropDownItem" to="/logiciels">
                    Logiciels
                  </Link>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="menu-item-divider"></div> 
              <div className="dropdown-services">
                <span className="services menuElements respMenuElem">Services</span>
                <div className="dropdown-content-services">
                  <Link className="telephonie dropDownItem link-no-underline" to="/téléphonie">
                    Téléphonie
                  </Link>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="menu-item-divider"></div>
              <Link className="contact menuElements respMenuElem" to="/contact">
                Contact
              </Link>
            </div>
            <div className="menu-item">
              <div className="menu-item-divider"></div> 
              <Link className="apropos menuElements aboutDisappear respMenuElem" to="/à-propos">
                À propos
              </Link>
            </div>
            <div className="userAccountNavDiv ">
            <UserAccount />
            </div>  
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
