import React, { useState, useEffect, useRef } from "react";
import BoxIcon from "../assets/email-icon.png";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  }
}
const MessageBox: React.FC = () => {
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [comment, setComment] = useState("");

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const handleFormSubmit = () => {
    if (!recaptchaValue) {
      alert("Completez le Captcha pour réinitialiser votre mot de passe.");
      return;
    }
    const subject = `Demande site web https://www.geneseinfo.fr/`;
    const body = `e-mail: ${email}\ndemande: ${selectedOption}\ncomment: ${comment}`;
    const mailto = `mailto:contact@geneseinfo.fr?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    toast.success('Votre message a bien été envoyé!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  
    window.location.href = mailto;
  };

  const imageClickHandler = () => {
    if (!showForm) {
      toggleForm();
    } else {
      closeForm();
    }
  };

  useEffect(() => {
    const image = imageRef.current;
    if (typeof window !== "undefined" && typeof window.grecaptcha !== "undefined") {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LdN71UoAAAAABXa3sppTKrYmARpf14rIw9IeKgB", { action: "submit" })
          .then((token: string) => {
            setRecaptchaValue(token);
          });
      });
    }

    if (image) {
      let isJiggling = false;

      const jiggleImage = () => {
        if (!isJiggling) {
          isJiggling = true;
          image.style.transform = "scale(1) rotate(20deg)";
          setTimeout(() => {
            image.style.transform = "scale(0.8) rotate(0deg)";
            isJiggling = false;
          }, 300);
        }
      };

      const jiggleInterval = setInterval(jiggleImage, 1500);

      return () => {
        clearInterval(jiggleInterval);
      };
    }

    if (showForm) {
      const outsideClickHandler = (e: MouseEvent) => {
        if (formRef.current && e.target instanceof Node && !formRef.current.contains(e.target)) {
          closeForm();
        }
      };

      document.addEventListener("click", outsideClickHandler);

      return () => {
        document.removeEventListener("click", outsideClickHandler);
      };
    }
  }, [showForm]);

  return (
    <div className="MessageBoxDiv">
      <img
        ref={imageRef}
        className="messageBoxImg"
        src={BoxIcon}
        alt=""
        onClick={imageClickHandler}
      />
      {showForm && (
        <div className="messageForm" ref={formRef}>
          <button className="closeButtonMessageForm" onClick={closeForm}>
            x
          </button>
          <h2>Contactez-nous</h2>
          <form className="messageBoxFormDiv">
            <input
              type="email"
              id="emailMessageBox"
              placeholder="Votre e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
                    <select
            id="demandeMessageBox"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value="" disabled>
              Votre demande concerne :
            </option>
            <option value="Réparation, achat de matériel, ou montage PC">
              Réparation, achat de matériel, ou montage PC
            </option>
            <option value="Forfait téléphonie, personnel ou d'entreprise">
              Forfait téléphonie, personnel ou d'entreprise
            </option>
            <option value="Intégration de logiciels, ou solutions de gestion">
              Intégration de logiciels, ou solutions de gestion
            </option>
            <option value="Mesures de sécurité réseau, pare-feu, solutions Watchguard">
            Mesures de sécurité réseau, pare-feu, solutions Watchguard
            </option>
            <option value=" Solutions d'hébergement, physique ou cloud">
            Solutions d'hébergement, physique ou cloud
            </option>
            <option value="Autre">
              Autre (Préciser dans le champs spécifique aux détails)
            </option>
          </select>
          <textarea
                  placeholder="Des détails à nous communiquer?"
                  id="commentMessageBox"
                  value={comment}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 500) {
                      setComment(inputValue);
                    }
                  }}
                  maxLength={500}
                />
                {comment.length >= 500 && <p className="mBoxLimit">*Limite de caractères atteinte</p>}
            <ReCAPTCHA
            className="captchaMessageBox"
          sitekey="6LdN71UoAAAAABXa3sppTKrYmARpf14rIw9IeKgB"
          onChange={(value: string | null) => setRecaptchaValue(value)}
        />
            <button onClick={handleFormSubmit}
            className="confirmFormMessageBox">Nous contacter</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MessageBox;
