import React, { useRef, useState, useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Mobiles from "../assets/phonePic-removebg-preview.png";
import Server from "../assets/serverHosting.jpg";
import Office from "../assets/Microsoft_Office_Complete_Guide-1.png";
import LogoHardware from "../assets/logos Hardware.png";
import LogoSecurity from "../assets/securityCarousel.jpg";
import { Link } from "react-router-dom";


const CarouselHome: React.FC = () => {
  const totalSlides = 5;
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<Carousel>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const deltaX = useRef(0);

  const thumbnails = [Mobiles, LogoHardware, Office, Server, LogoSecurity];

  const handleThumbnailClick = (index: number) => {
    setCurrentSlide(index);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    isDragging.current = true;
    startX.current = event.clientX;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging.current) {
      const distance = event.clientX - startX.current;
      
      if (distance > 500 && currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
        startX.current = event.clientX;
      } else if (distance < -500 && currentSlide < totalSlides - 1) {
        setCurrentSlide(currentSlide + 1);
        startX.current = event.clientX;
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (isDragging.current) {
       
        if (deltaX.current < -50 && currentSlide < totalSlides - 1) {
          setCurrentSlide(currentSlide + 1);
        } else if (deltaX.current > 50 && currentSlide > 0) {
          setCurrentSlide(currentSlide - 1);
        }
        deltaX.current = 0;
      }
    }, 100);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div
      className="carouselHome"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Carousel
        showThumbs={false}
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
        selectedItem={currentSlide}
        ref={carouselRef}
        transitionTime={2000}
        showStatus={false}
        showArrows={false}
      >
        <div className="carouselHomeFrame carouselHomeFrameMobile">
                <img className="carouselHomeImg"src={Mobiles} alt="" />
                <h1>Communication et Téléphonie</h1>
                  <p className="mobileCarouselHomeP"><span>Nous proposons à nos abonnées une gamme de solutions télécom et IT à destination des entreprises et collectivités</span> :
                  <p>
                  <span className="telephonieTitlesCarousel">Liens multi-infrastructures</span> : Bouygues, Orange, SFR, Covage, Axione … & multi-technos : xDSL, FTTO, FTTH…
                  <br />
                  <span className="telephonieTitlesCarousel">Communications unifiées</span> : Connecteur Teams, softphone, visioconférence…
                  <br />
                  <span className="telephonieTitlesCarousel">Téléphonie Fixe</span> : Centrex, Trunk-SIP…
                  <br />
                  <span className="telephonieTitlesCarousel">Mobile</span> : MVNO Orange, Bouygues, SIM IoT...
                  </p>
                </p>
                <Link to="/téléphonie">
                    <button className="productCardsButton">En savoir plus</button>
                </Link>
                </div>
        <div className="carouselHomeFrame">
                <img className="carouselHomeImg" src={LogoHardware} alt="" />
                <h1>Matériel informatique</h1>
                <p>PC, serveurs, NAS, Tablettes, Smartphone, Camera sur IP et accessoires.</p>
                <Link to="/matériel">
                    <button className="productCardsButton">En savoir plus</button>
                </Link>
        </div>
        <div className="carouselHomeFrame">          
                <img className="carouselHomeImg"src={Office} alt="" />
                <h1>Intégration de logiciels</h1>
                <p>Solutions de gestion.
                Partenaire <span id="microsoftCarousel">microsoft </span>et intégrations OS, développement sur toutes la gamme <span id="officeCarousel">office</span>.
                Solutions Cloud.
                </p>
                <Link to="/logiciels">
                    <button className="productCardsButton">En savoir plus</button>
                </Link>
        </div>
        <div className="carouselHomeFrame">
                <img className="carouselHomeImg"src={Server} alt="" />
                <h1>Hébergement sur nos servers ou externalisé</h1>
                <p>Solutions d'hébergement, serveurs disponibles en interne.
                </p>
                <Link to="/logiciels">
                    <button className="productCardsButton">En savoir plus</button>
                </Link>
        </div>
        <div className="carouselHomeFrame">
                <img className="carouselHomeImg"src={LogoSecurity} alt="" />
                <h1>Solutions antivirus et protection</h1>
                <p>Partenaire <a href="https://www.watchguard.com/" id="watchGuard"target="_blank" rel="noopener noreferrer">Watchguard Technologies</a></p>

                <Link to="/sécurité">
                    <button className="productCardsButton">En savoir plus</button>
                </Link>
        </div>
      </Carousel>
      <div className="thumbnailSection">
        {thumbnails.map((thumbnail, index) => (
          <img
            key={index}
            src={thumbnail}
            alt={`Thumbnail ${index}`}
            className={`thumbnail ${currentSlide === index ? "activeThumbnail" : ""}`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default CarouselHome;