import React, { useState, ChangeEvent, FormEvent } from "react";
import { displayErrorToast, displaySuccessToast } from "./utils/errorServices";

const Register = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    gender: "Homme",
    engagementPromo: "no",
    accountType: "particulier",
    siret: "",
    companyName: "",
  
    firstName: "Votre prénom",
    lastName: "Votre nom",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value, type } = e.target;
    let newValue: string | boolean = value;

    if (type === "checkbox") {
      newValue = (e.target as HTMLInputElement).checked ? "yes" : "no";
    }

    setFormData({ ...formData, [name]: newValue });
  };

  const handleAccountTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAccountType = e.target.value;

   
    const newFirstName = newAccountType === "particulier" ? "Votre prénom" : "";
    const newLastName = newAccountType === "particulier" ? "Votre nom" : "";

    setFormData({
      ...formData,
      accountType: newAccountType,
      siret: "",
      companyName: "",
      firstName: newFirstName,
      lastName: newLastName,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:4000/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      console.log(response.status);
      if (response.ok) {
        const data = await response.json();
        console.log("Registration successful");
        displaySuccessToast("Inscription réussi");
      } else {
        console.log(response.status);
        console.error("Registration failed");
        displayErrorToast("Erreur lors de l'inscription");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      displayErrorToast("An error occurred");
    }
  };

  return (
    <div className="accountRegisterDiv">
      <form onSubmit={handleSubmit}>
        <h1>Création de compte</h1>
        <div className="yourIDregister">
          <h2>Vos identifiants</h2>
          <input
            type="email"
            placeholder="Email"
            className="yourIDregister"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="password"
            placeholder="Mot de passe"
            className="yourIDregister"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <hr className="accRegisterHR" />
        <div className="yourPInfos">
          <h3>Vos informations personnelles</h3>
          <div className="radioButtonDiv radioButtonDivOne">
            <label>
              <input
                type="radio"
                name="accountType"
                value="particulier"
                className="radioBtnStyle"
                checked={formData.accountType === "particulier"}
                onChange={handleAccountTypeChange}
              />
              Particulier
            </label>
            <label>
              <input
                type="radio"
                name="accountType"
                value="entreprise"
                className="radioBtnStyle"
                checked={formData.accountType === "entreprise"}
                onChange={handleAccountTypeChange}
              />
              Entreprise
            </label>
          </div>
          {formData.accountType === "particulier" ? (
            <div className="nameRegisterDiv">
              {/* Display two inputs when "particulier" is selected */}
              <input
                type="text"
                placeholder="Votre prénom"
                className="yourIDregisterName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Votre nom"
                className="yourIDregisterName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          ) : formData.accountType === "entreprise" ? (
            <div className="nameRegisterDiv">
              <input
                type="text"
                placeholder="Numéro de SIREN"
                className="yourIDregisterName"
                name="siret"
                value={formData.siret}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Nom de l'entreprise"
                className="yourIDregisterName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>
          ) : null}
          <label htmlFor="engagementPromo">
            J'accepte de recevoir des informations et des offres promotionnelles exclusives de notre part
            <input
              name="engagementPromo"
              type="checkbox"
              className="promoCheckbox"
              checked={formData.engagementPromo === "yes"}
              onChange={handleChange}
            />
          </label>
        </div>
        <button type="submit" className="registerButton">
          S'inscrire
        </button>
      </form>
    </div>
  );
};

export default Register;
