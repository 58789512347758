import React from "react";
import { Link } from "react-router-dom";

const UserLoginBox: React.FC = () => {
  return (
    <div className="userLoginBox">
      <div className="alreadyRegisteredDiv">
        <p>Déjà inscrit?</p>
        <form action="POST" className="alreadyRegisteredDiv">
          <input type="text" placeholder="E-mail" className="registeredField"/>
          <input type="password" placeholder="Mot de passe"className="registeredField" />
          <button type="submit"className="registeredButton">Connexion</button>
          <label className="stayConnectedLabel">
            <input type="checkbox" className="stayConnectedCheckbox"/> <p className="stayConnected">Rester connecté</p>
          </label>
        </form>
      </div>
      <div>
        <Link to="/mot-de-passe-oublié" className="forgottenPWlink"><p className="forgottenPW">Avez-vous oublié votre mot de passe?</p></Link>
      </div>
      <div className="alreadyRegisteredDiv">
        <p>Nouveau client?</p>
        <Link to="/register"><button className="alreadyRegisteredButton">Créer un compte</button></Link>
      </div>
    </div>
  );
};

export default UserLoginBox;
