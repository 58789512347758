import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SimpleMessageBox: React.FC = () => {
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [comment, setComment] = useState("");
  const [showForm, setShowForm] = useState(true); 

  const closeForm = () => {
    setShowForm(false); 
  };

  const handleFormSubmit = () => {
    if (!recaptchaValue) {
      alert("Completez le Captcha pour réinitialiser votre mot de passe.");
      return;
    }
    const subject = `Demande site web https://www.geneseinfo.fr/`;
    const body = `e-mail: ${email}\ndemande: ${selectedOption}\ncomment: ${comment}`;
    const mailto = `mailto:contact@geneseinfo.fr?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    toast.success('Votre message a bien été envoyé!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    window.location.href = mailto;
  };
  if (!showForm) {
    return null;
  }

  return (
    <div className="messageForm messageFormSecurityPage">
        <button className="closeButtonMessageForm" onClick={closeForm}>
            x
          </button>
      <h2>Contactez-nous</h2>
      <form className="messageBoxFormDiv">
        <input
          type="email"
          id="emailMessageBox"
          placeholder="Votre e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
       <select
            id="demandeMessageBox"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value="" disabled>
              Votre demande concerne :
            </option>
            <option value="Réparation, achat de matériel, ou montage PC">
              Réparation, achat de matériel, ou montage PC
            </option>
            <option value="Forfait téléphonie, personnel ou d'entreprise">
              Forfait téléphonie, personnel ou d'entreprise
            </option>
            <option value="Intégration de logiciels, ou solutions de gestion">
              Intégration de logiciels, ou solutions de gestion
            </option>
            <option value="Mesures de sécurité réseau, pare-feu, solutions Watchguard">
            Mesures de sécurité réseau, pare-feu, solutions Watchguard
            </option>
            <option value=" Solutions d'hébergement, physique ou cloud">
            Solutions d'hébergement, physique ou cloud
            </option>
            <option value="Autre">
              Autre (Préciser dans le champs spécifique aux détails)
            </option>
          </select>
        <textarea
          placeholder="Des détails à nous communiquer?"
          id="commentMessageBox"
          value={comment}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 500) {
              setComment(inputValue);
            }
          }}
          maxLength={500}
        />
        {comment.length >= 500 && <p className="mBoxLimit">*Limite de caractères atteinte</p>}
        <ReCAPTCHA
          className="captchaMessageBox"
          sitekey="6LdN71UoAAAAABXa3sppTKrYmARpf14rIw9IeKgB" 
          onChange={(value: string | null) => setRecaptchaValue(value)}
        />
        <button onClick={handleFormSubmit} className="confirmFormMessageBox">
          Nous contacter
        </button>
      </form>
    </div>
  );
};

export default SimpleMessageBox;