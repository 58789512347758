import React, { useState } from "react";
import VotreSécurité from "../assets/votreSécurité.png";
import SécuritéServices from "../assets/logoTechnoWatchguard.png";
import MessageBox  from "../components/messageBoxForm";

const SecurityMain: React.FC = () => {
  const [showMessageBox, setShowMessageBox] = useState(false);

  const toggleMessageBox = () => {
    setShowMessageBox(!showMessageBox);
  };
  return (
    <div className="securityBodyMain">
      <div className="securityPriority">
        <img src={VotreSécurité} alt="" />
      </div>
      <div className="securityServices">
        <div className="servicesWGdiv">
            <p className="servicesWG servicesWG1">Services de sécurité</p>
            <p className="servicesWG servicesWG2">Appliances Firewall</p>
            <p className="servicesWG servicesWG3">FirewallCloud et virtuels</p>
            <p className="servicesWG servicesWG4">Gestion desplateformes</p>
        </div>
        <img className="securityPrioImg"src={SécuritéServices} alt="" />
        <p className="WGtext">WatchGuard Firebox est une appliance 
          de sécurité réseau avancée et exhaustive. 
          Elle redonne aux professionnels de l'informatique 
          le contrôle sur leurs réseaux grâce à des outils de 
          visibilité sur les menaces et une sécurité de pointe,
           aisément déployables et adaptés à tout type d'entreprise, 
           quels que soient leur budget, leur taille ou leur complexité.</p>
      </div>
      <div className="protectionDiv">
        <h1>Une sécurité réseau unifiée pour une meilleure protection</h1>
        <p>L'architecture de la plateforme de sécurité unifiée de 
          WatchGuard va bien au-delà de la sécurité réseau. En effet,
           elle protège entièrement les endpoints, les identités, 
           le Wi-Fi et bien plus encore, grâce à des renseignements 
           sur les menaces inter-produits, la gestion centralisée et
            l'agilité opérationnelle. Pour bloquer les menaces de plus
             en plus sophistiquées 
          et en évolution constante, adoptez une approche unifiée.</p>
          <p className="contactezNoustxt">Contactez-nous dès maintenant pour en apprendre 
            d'avantages et beneficier de nos solutions! </p>
            <button onClick={toggleMessageBox}>Nous envoyer une demande</button>
      </div>
      {showMessageBox && <MessageBox />}

    </div>
  );
};

export default SecurityMain;
