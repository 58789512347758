import React from "react";

const GoogleMapApi: React.FC = () => {
  return (
    <div className="googleMapMainContainer">
      <div className="googleMapMain">
        <iframe
          title="Google Map"
          width="600"
          height="450"
          style={{ border: "0" }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d385.1420087451921!2d5.694729180307697!3d49.50497505839659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eac6fb7dba9a9b%3A0x2acd6ec1da7dd31f!2sGENESE%20INFORMATIQUE!5e0!3m2!1sen!2sfr!4v1695299774866!5m2!1sen!2sfr&key=AIzaSyC1756aHIGOMCZ7n_vRU-btfKwuH7S_Eto"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMapApi;
