import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import UserLoginBox from "./userLoginBox"; 

const UserAccount: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="userAccountMain"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FontAwesomeIcon icon={faUser} className="userAccountIcon" />
      <p className="votreCompte">Votre compte</p>
      {isHovered && <UserLoginBox />}
    </div>
  );
};

export default UserAccount;
